/*
    Frontend form validation
    author: Eline van Hooff
    Global validation functions


    The validation property of the form field defines which rule is used for the validation
    For implementation for use in new form fields, use the validationMixin
*/

import Vue from 'vue';
import { definePlugin } from '@dundle/utils/nuxt';

const EMAIL_REGEX = /\S+@\S+\.\S+/;
const LATIN_EMAIL_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
// DEV-5892 show message when email address is ending with .con, .col, .cin, .cim, .comm
const EMAIL_REGEX_MESSAGE = /\S+@\S+\.(?!con$|col$|cin$|cim$|comm$)\S+/;
const PHONE_REGEX = /^[0-9-+()]*$/;
const NMBRS_REGEX = /^[0-9]*$/;

export default definePlugin(({ app }, inject) => {
    inject(
        'validation',
        new Vue({
            data() {
                return {
                    valid: {},
                    errors: {},
                    validationRules: {
                        required: {
                            rules: [value => (value && value.length !== 0) || 'error'],
                        },
                        checked: {
                            // checkbox value holds DOMString on form validation, not boolean, double = does not work
                            rules: [value => value === true || value === 'true' || 'error'],
                        },
                        email: {
                            rules: [
                                value => value.length !== 0 || 'error',
                                value => LATIN_EMAIL_REGEX.test(value) || 'warning',
                                value => EMAIL_REGEX_MESSAGE.test(value) || 'message',
                            ],
                        },
                        phoneNumber: {
                            rules: [
                                value => value.length !== 0 || 'error',
                                value => PHONE_REGEX.test(value) || 'warning',
                            ],
                        },
                        number: {
                            rules: [
                                value => value.length !== 0 || 'error',
                                value => NMBRS_REGEX.test(value) || 'error',
                            ],
                        },
                        dateOfBirth: {
                            // value holds DOMString on form validation, so getYear() triggers error when it's not a date
                            rules: [
                                value => value.length !== 0 || 'error',
                                value => (value !== '' && new Date(value).getYear() !== 0) || 'warning',
                            ], // getYear is the year minus 1900
                        },
                        dateMonth: {
                            rules: [value => !!value || 'error', value => Number(value) <= 12 || 'warning'],
                        },
                        dateDay: {
                            rules: [value => !!value || 'error', value => Number(value) <= 31 || 'warning'],
                        },
                        dateYear: {
                            rules: [
                                value => !!value || 'error',
                                value => (value && Number(value) >= 1900) || 'warning',
                            ],
                        },
                    },
                };
            },
            methods: {
                validateField({ value, validation }) {
                    const result = this.validationRules[validation].rules
                        .map(rule => {
                            // validationType can be bool true (then input is valid), string 'error' or string 'warning'
                            const validationType = rule(value);
                            // only return the error/warning string
                            if (validationType !== true) return validationType;
                            return undefined;
                        })
                        .filter(Boolean); // filter undefined
                    return result;
                },

                /*
                    Function for validating the fields within a form. 
                    Use this on the page.
                    
                    Needs the DOM element which contains all form inputs that need validating.
                    When form element does not have property validation, it will be skipped.

                    @param {form}       DOM element.
                                        examples: 
                                        - e.target (when UIButton is submit type and within the form element)
                                        - this.$refs.normalForm (when UIButton uses click event)
                    @return {Boolean}   Returns false when validation ruels are not met.
                */
                validateForm(form) {
                    const fields = form.querySelectorAll('[validation]');
                    let formIsValid = true;
                    for (const field of Object.values(fields)) {
                        const validation = field.getAttribute('validation');
                        const { id, value } = field;
                        const inputErrors = this.validateField({ value, validation });

                        // DEV-5892 let the user continue with the form when the error is 'message'
                        if (inputErrors?.length) {
                            this.$set(this.errors, id, inputErrors[0]);
                        }

                        const blockingErrors = inputErrors.filter(errorType => errorType !== 'message');
                        if (blockingErrors?.length) {
                            formIsValid = false;
                            this.$set(this.valid, id, undefined);
                        } else {
                            this.$set(this.valid, id, true);
                        }
                    }
                    return formIsValid;
                },

                /*
                    Function for validating the form fields on input change.
                    Don't use this function, but use the validateOnChange function in the mixin to bind the function to the correct component.
                */
                validateOnChange({ id, value, validation }) {
                    const inputErrors = this.validateField({ value, validation });

                    if (inputErrors?.length) {
                        this.$set(this.errors, id, inputErrors[0]);
                        this.$set(this.valid, id, undefined);
                    } else {
                        this.$set(this.errors, id, undefined);
                        this.$set(this.valid, id, true);
                    }
                },
                resetValidation() {
                    this.errors = {};
                    this.valid = {};
                },
                overrideWarning(id) {
                    this.$set(this.valid, id, undefined);
                    this.$set(this.errors, id, 'warning');
                },
                overrideError(id) {
                    this.$set(this.valid, id, undefined);
                    this.$set(this.errors, id, 'error');
                },
            },
        })
    );
});
